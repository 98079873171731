<template>
	<div
		v-if="!is_card || is_confirm"
		class="flex-column full-height bg-white position-relative"
	>
		<div
			class="full-height overflow-y-auto pa-20 position-relative"
		>
			<div class="mt-20">
				<label
				>카드번호</label>
				<div class="flex-row justify-center mt-10 box  pa-10 text-center">
					<input
						v-model="item.card_number[0]"
						placeholder="0000"
						class="flex-1 text-center"
						type="number"
						:rules="$rules.numeric(item.card_number, 0, 4)"
						@keyup="onFocus($event, 4, 'card_number_1')"
					/>
					<input
						v-model="item.card_number[1]"
						placeholder="0000"
						class="flex-1 text-center"
						ref="card_number_1"
						type="password"
						:rules="$rules.numeric(item.card_number, 1, 4)"
						@keyup="onFocus($event, 4, 'card_number_2')"
					/>
					<input
						v-model="item.card_number[2]"
						placeholder="0000"
						class="flex-1 text-center"
						ref="card_number_2"
						type="password"
						:rules="$rules.numeric(item.card_number, 2, 4)"
						@keyup="onFocus($event, 4, 'card_number_3')"
					/>
					<input
						v-model="item.card_number[3]"
						placeholder="0000"
						class="flex-1 text-center"
						ref="card_number_3"
						type="number"
						:rules="$rules.numeric(item.card_number, 3, 4)"
						@keyup="onFocus($event, 4, 'valid_term_mm')"
					/>
				</div>
			</div>
			<div class="mt-20">
				<div>
					<label
					>유효기간</label>
					<div class="mt-10 flex-row justify-space-betweens ">
						<input
							v-model="item.valid_term_mm"
							:rules="$rules.numeric(item, 'valid_term_mm', 2)"
							class="input-box mr-10 text-center"
							ref="valid_term_mm"
							placeholder="mm"
							@keyup="onFocus($event, 2, 'valid_term_year')"
						>
						<input
							v-model="item.valid_term_year"
							:rules="$rules.numeric(item, 'valid_term_year', 2)"
							class="input-box text-center"
							ref="valid_term_year"
							placeholder="yy"
							@keyup="onFocus($event, 2, 'card_pw')"
						>
					</div>
				</div>
			</div>
			<div class="mt-20 flex-row justify-space-between">
				<div class="flex-1 mr-10">
					<label
					>비밀번호</label>
					<input
						v-model="item.card_pw"
						type="password"
						class="mt-10 input-box text-center"
						placeholder="비밀번호 앞 2자리"
						ref="card_pw"
						:rules="$rules.numeric(item, 'card_pw', 2)"
						@keyup="onFocus($event, 2, 'installment')"
					>
				</div>
				<div class="flex-1 ">
					<label
					>할부</label>
					<select
						v-model="item.installment"
						class="mt-10 input-box text-center"
						ref="installment"
					>
						<option value="00">일시불</option>
						<option
							v-for="i in 10"
							:key="'installment_' + i"
							:value="('0' + (i + 1)).slice(-2)"
						>{{ i + 1}}개월</option>
					</select>
				</div>
			</div>
			<div
				v-if="item.card_div_type == 'personal'"
				class="mt-20"
			>
				<label
				>생년월일</label>
				<input
					v-model="item.auth_no"
					class="mt-10 input-box"
					ref="personal_biz_certfc_info"
					placeholder="예시) 190101"
					:rules="$rules.numeric(item, 'auth_no', 6)"
					@keyup="onFocus($event, 6, 'buyer_name')"
					:readonly="is_card"
				>
			</div>
			<div
				v-else
				class="mt-20"
			>
				<label
				>사업자번호</label>
				<input
					v-model="item.auth_no"
					class="mt-10 input-box"
					ref="personal_biz_certfc_info"
					placeholder="사업자번호 10자리"
					:rules="$rules.numeric(item, 'auth_no', 10)"
					@keyup="onFocus($event, 10, 'buyer_name')"
					:readonly="is_card"
				>
			</div>

			<div
				class="mt-20"
			>
				<label>주문자 명</label>
				<input
					v-model="item.buyer_name"
					class="mt-10 input-box"
					ref="buyer_name"
					placeholder="주문자명을 입력하세요"
					max="20"
					:readonly="is_card"
				>
			</div>

			<div
				class="mt-20"
			>
				<label>주문자 연락처</label>
				<input
					v-model="item.buyer_tell"
					class="mt-10 input-box"
					ref="buyer_tell"
					placeholder="주문자 연락처를 입력하세요"
					max="10"
				>
			</div>

			<div
				class="mt-20"
			>
				<label>상품명</label>
				<input
					v-model="item.pdt_name"
					class="mt-10 input-box"
					ref="pdt_name"
					placeholder="상품명을 입력하세요"
					max="20"
				>
			</div>

			<div
				v-if="seller_info.is_payment_limit > 0"
				class="mt-20"
			>
				<label>1회 결제 한도</label>
				<input
					v-model="seller_info.payment_limit"
					type="number"
					class="mt-10 input-box"
					disabled
				>
			</div>

			<div
				class="mt-20"
			>
				<label>결제금액</label>
				<input
					v-model="item.pdt_price"
					type="number"
					class="mt-10 input-box"
					ref="pdt_price"
					placeholder="결제금액을 입력하세요"
					:rules="$rules.max(item, 'pdt_price', 9)"
				>
			</div>
		</div>
		<div
			class="mt-auto"
		>
			<button
				v-if="!is_card || is_confirm"
				class="btn btn-primary"
				:disabled="is_disabled"
				@click="postPayment"
			>결제요청</button>
			<button
				v-else
				class="btn btn-primary"
				@click="postCardConfirm"
			>카드 조회</button>
		</div>

		<PaymentResult
			v-if="is_on_result"
			:user="user"
			:payment_info="item"

		></PaymentResult>
	</div>
	<div
		v-else
		class="full-height flex-column justify-center items-center bg-gray"
	>
		<div class=" text-center color-white">

			<p class="size-px-24 font-weight-700">카드인증이 진행중입니다</p>
			<p class="mt-10 pa-20 size-px-16">인증 화면이 보이지 않는다면 팝업 차단을 해제해주세요</p>
		</div>
		<Loading v-if="false"></Loading>
		<iframe :src="card_confirm_url" style="width: 0%; height: 0%; border: none"></iframe>
	</div>
</template>
<script>
import PaymentResult from "./PaymentResult";
import Loading from "../Layout/Loading";
export default {
	name: 'PaymentOrder'
	, components: {Loading, PaymentResult}
	, props: ['user', 'seller_info']
	, data: function(){
		return {
			program: {
				name: '추가 결제'
				, title: '추가 결제'
				, bottom: false
				, top: false
			}
			, item: {
				card_number: []
				, card_div_type: 'personal'
				, installment: '00'
				, pdt_price: ''
				, pdt_name: ''
				, buyer_name: ''
				, auth_no: ''
				, shop_uid: this.$route.params.shop_uid
			}
			, is_on_result: false
			, is_confirm: false
			, items_shop: []
		}
	}
	, computed: {
		is_disabled: function(){
			let t = true

			if(this.card_number && this.valid_yymm && this.item.pdt_price && this.item.pdt_name ){
				t = false
			}
			return t
		}
		, card_number: function(){
			let t = this.item.card_number.join('')

			return t
		}
		, card_confirm_url: function(){
			let t = ''

			let domain = process.env.VUE_APP_DOMAIN_PAYMENT
			let dev = process.env.VUE_APP_DEV
			let server = process.env.VUE_APP_SERVER_PAYMENT
			let location = window.location.hostname

			if(location == domain){
				t = server
			}else{
				t = dev
			}
			t += '/inicis/postCardRequest'

			return t
		}
		, valid_yymm: function(){
			let t = ''
			t += this.item.valid_term_year + this.item.valid_term_mm
			return t
		}
		, is_card: function(){
			console.log('is_card')
			let t = false
			if(this.$common.getPowNumber(this.shop_info.sales_type, 3)){
				t = true
			}

			return t
		}
		, shop_info: function(){

			let t = {}
			console.log('shop_info')
			this.items_shop.filter( (item) => {

				if(item.uid == this.$route.params.shop_uid){
					t = item
				}
			})
			return t
		}

	}
	, methods: {
		postPayment: async function(){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$Axios({
					method: 'post'
					, url: '/payment/postPaymentOrder'
					, data: {
						card_div_type: this.item.card_div_type
						, pdt_price: this.item.pdt_price
						, pdt_name: this.item.pdt_name
						, buyer_name: this.item.buyer_name
						, buyer_tell: this.item.buyer_tell
						, installment: this.item.installment
						, card_number: this.card_number
						, valid_yymm: this.valid_yymm
						, auth_no: this.item.auth_no
						, card_pw: this.item.card_pw
						, o_uid: this.$route.params.idx
					}
				})
				if(result.success){
					this.is_on_result = true
				}else{
					throw result.message
				}
			}catch (e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onFocus: function(e, max, target){
			if(e.target.value.length >= max){
				this.$refs[target].focus()
			}
		}
		, postCardConfirm: async function(){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$Axios({
					method: 'post'
					, url: '/inicis/postCardRequest'
				})
				if(result.success){
					this.is_confirm = false
				}else{
					throw result.message
				}
			}catch (e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getShop: async function(){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$Axios({
					method: 'post'
					, url: 'main/index'
					, version: 'v2'
				})
				if(result.success){
					this.items_shop = result.data
				}else{
					throw result.message
				}
			}catch (e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

	}
	, created() {
		this.$bus.$emit('onLoad', this.program)

		this.getShop();

		window.addEventListener('message', (e) => {
			console.log('on message', e.data)
			let data = JSON.parse(e.data)

			if(data.type == 'cardConfirm'){

				try{

					if(data.result == 'success'){
						let auth = JSON.parse(data.data)
						this.item.buyer_name = auth.Name
						this.item.auth_no = (auth.Socialno).slice(-6)
						this.is_confirm = true
						this.$bus.$emit('notify', { type: 'success', message: data.message })
					}else if(data.result == 'cancel'){
						this.$router.push({ name: this.program.from})
						throw data.message
					}else{
						throw data.message
					}
				}catch (e){
					console.log('addEventListener', e)
					this.$bus.$emit('notify', { type: 'error', message: e })
				}
			}
		})
	}
	, watch: {
		'item.pdt_price': {
			handler: function(call){
				if(this.seller_info.is_payment_limit > 0 && Number(call) > Number(this.seller_info.payment_limit)){
					this.item.pdt_price = ''
					this.$bus.$emit('notify', { type: 'error', message: '1회 결제 한도를 초과하였습니다.' })
				}
			}
		}
	}
}
</script>